import {
    USER_LOGIN, USER_REGISTRATION, USER_SOCIAL_LOGIN, USER_FORGOT_PASSWORD, USER_RESET_PASSWORD,EMAIL_VERIFICATION,
    USER_LOGOUT, OPEN_LOGIN_MODAL, OPEN_REGISTRATION_MODAL, OPEN_FORGOT_MODAL, CLOSE_ALL_MODAL, UPDATE_PROFILE, UPDATE_PICTURE
} from '../actions/auth-action';


const initState = {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    csrf_token: localStorage.getItem('csrf_token') ? JSON.parse(localStorage.getItem('csrf_token')) : null,
    loginOpen: false,
    registrationOpen: false,
    forgotOpen: false,
    userForms: localStorage.getItem('user') ? Object.entries(JSON.parse(localStorage.getItem('user')).form).filter(obj=>obj[1] === true).map(obj=>obj[0])  : [],
};

export default (state = initState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,
                user: !action.error ? action.data : null,
                userForms: !action.error ? Object.entries(action.data.form).filter(obj=>obj[1] === true).map(obj=>obj[0]) : [],
                csrf_token: !action.error ? action.csrf_token : ''
            };
        case USER_SOCIAL_LOGIN:
            return {
                ...state,
                user: !action.error ? action.data : null,
                csrf_token: !action.error ? action.csrf_token : ''
            }
        case OPEN_LOGIN_MODAL:
            return {
                ...state,
                registrationOpen: false,
                forgotOpen: false,
                loginOpen: true
            }
        case OPEN_REGISTRATION_MODAL:
            return {
                ...state,
                forgotOpen: false,
                loginOpen: false,
                registrationOpen: true,
            }
        case OPEN_FORGOT_MODAL:
            return {
                ...state,
                registrationOpen: false,
                loginOpen: false,
                forgotOpen: true
            }
        case CLOSE_ALL_MODAL:
            return {
                ...state,
                registrationOpen: false,
                loginOpen: false,
                forgotOpen: false
            }
        case USER_LOGOUT:
            return {
                ...state,
                user: {},
                csrf_token: '',

            }
        case UPDATE_PROFILE:
            return {
                ...state,
                user: !action.error ? action.data : state.user,
            }
        case UPDATE_PICTURE:
            return {
                ...state,
                user: !action.error ? {...state.user, ['picture']:action.data.picture}  : state.user,
            }
        case EMAIL_VERIFICATION:
            return {
                ...state,
                user: !action.error ? action.data : null,
                csrf_token: !action.error ? action.csrf_token : ''
            }
    }

    return state;
}

