import {
    USER_ROLES, INTEREST_MASTER, LOADING_DATA, GET_DATA_BY_TYPE, FETCH_DESTINATION_DATA, EVENT_MASTER, SERVICE_MASTER,
    FETCH_DESTINATION_DETAILS, FETCH_SMART_SOLUTION_DATA, FETCH_BIKE_EXPERT_DATA, FETCH_EVENTS_DATA, FETCH_SERVICES_DATA, FETCH_SERVICES_DETAILS,
    FETCH_BUY_DATA, FETCH_BUY_DETAILS, FETCH_SMART_SOLUTION_DETAILS, FETCH_EVENT_DETAILS, FETCH_BIKE_EXPERT_DETAILS, ADD_MY_COUNTRY, ADD_FAVOURITE,
    RESET_REVIEW_LISTING, RESET_SEARCH, FETCH_USER_BUY_LIST, GET_MY_STATISTIC
} from '../actions/common-action';


const initState = {
    roles: [],
    interest_master: [],
    event_master: [],
    service_master: [],
    buy_data: [],
    repair_data: [],
    destinationData: {},
    isLoading: false,
    destinationDetails: {},
    smartSolutionData: {},
    smartSolutionDetails: {},
    bikeExpertData: {},
    bikeExpertDetailData: {},
    eventData: {},
    eventDetailsData: {},
    buyData: {},
    buyDetailsData: {},
    servicesData: {},
    servicesDetailData: {},
    myCountry: localStorage.getItem('countryName') || null,
    myCountryCode: null,
    resetReviewList: false,
    resetSearch: false,
    userBuyData: {},
    myStaticData:{},
    shopTypeMaster:[{key:1, label:'Individual'}, {key:2, label:'Shop'},{key:3, label:'Recycle'},{key:4, label:'Used Bikes'},{key:5, label:'Clubs'},{key:6, label:'Teams'}]
}

export default (state = initState, action) => {

    // eslint-disable-next-line default-case
    switch (action.type) {
        case USER_ROLES:
            return {
                ...state,
                roles: !action.error ? action.data : []
            }
        case EVENT_MASTER:
            return {
                ...state,
                event_master: !action.error ? action.data : []
            }
        case INTEREST_MASTER:
            return {
                ...state,
                interest_master: !action.error ? action.data : []
            }
        case SERVICE_MASTER:
            return {
                ...state,
                service_master: !action.error ? action.data : []
            }
        case LOADING_DATA:
            return {
                ...state,
                isLoading: action.status
            }
        case GET_DATA_BY_TYPE:
            if (action.data_type === 'buy') {
                return {
                    ...state,
                    buy_data: !action.error ? action.data : []
                }
            } else if (action.data_type === 'rent') {
                return {
                    ...state,
                    rent_data: !action.error ? action.data : []
                }
            } else if (action.data_type === 'repair') {
                return {
                    ...state,
                    repair_data: !action.error ? action.data : []
                }
            }
        // eslint-disable-next-line no-fallthrough
        case FETCH_DESTINATION_DATA: {
            return {
                ...state,
                destinationData: action.data
            }
        }
        case FETCH_EVENTS_DATA: {
            return {
                ...state,
                eventData: action.data
            }
        }
        case FETCH_BUY_DATA: {
            return {
                ...state,
                buyData: action.data
            }
        }
        case FETCH_SERVICES_DATA: {
            return {
                ...state,
                servicesData: action.data
            }
        }
        case FETCH_SMART_SOLUTION_DATA: {
            return {
                ...state,
                smartSolutionData: action.data
            }
        }

        case FETCH_SMART_SOLUTION_DETAILS: {
            return {
                ...state,
                smartSolutionDetails: action.data
            }
        }

        case FETCH_BIKE_EXPERT_DATA: {
            return {
                ...state,
                bikeExpertData: action.data
            }
        }

        case FETCH_DESTINATION_DETAILS: {
            return {
                ...state,
                destinationDetails: action.data
            }
        }

        case FETCH_BUY_DETAILS: {
            return {
                ...state,
                buyDetailsData: action.data
            }
        }
        case FETCH_SERVICES_DETAILS: {
            return {
                ...state,
                servicesDetailData: action.data
            }
        }
        case FETCH_EVENT_DETAILS: {
            return {
                ...state,
                eventDetailsData: action.data
            }
        }
        case FETCH_BIKE_EXPERT_DETAILS: {
            return {
                ...state,
                bikeExpertDetailData: action.data
            }
        }

        case ADD_MY_COUNTRY: {
            localStorage.setItem('countryName', action.data.countryName);
            return {
                ...state,
                myCountry: action.data.countryName,
                myCountryCode: action.data.countryCode
            }
        }
        /* case ADD_FAVOURITE: {
            console.log(action, 'actio')
            if(!action.error && action.data.type ==='buy'){
                const index = state.buyData.data.findIndex(obj=>obj.content_id == action.data.content_id)
                var buyData =  state.buyData.data[index]
                buyData.favourite_count  = Number(buyData.favourite_count) + 1;
                buyData.is_already_favourite = "1";
                 return {
                    ...state,
                    buy_data: [...state.buy_data.slice(0, index),buyData, ...state.buy_data.slice(index + 1)]
                } 
            }else if(!action.error && action.data.type ==='solution'){
                const index = state.smartSolutionData.data.findIndex(obj=>obj.content_id == action.data.content_id)
                let smartData =  state.smartSolutionData.data[index]
                smartData.favourite_count = Number(smartData.favourite_count) + 1
                smartData.is_already_favourite = "1";
                 return {
                    ...state,
                    smartSolutionData: [...state.smartSolutionData.slice(0, index),smartData, ...state.smartSolutionData.slice(index + 1)]
                } 
            }else if(!action.error && action.data.type ==='expert'){
                const index = state.bikeExpertData.data.findIndex(obj=>obj.content_id == action.data.content_id)
                let expertData =  state.bikeExpertData.data[index]
                expertData.favourite_count = Number(expertData.favourite_count) + 1;
                expertData.is_already_favourite = "1";
                 return {
                    ...state,
                    expertData: [...state.expertData.slice(0, index),expertData, ...state.expertData.slice(index + 1)]
                } 
            }else if(!action.error && action.data.type ==='event'){
                const index = state.eventData.data.findIndex(obj=>obj.content_id == action.data.content_id)
                let evData =  state.eventData.data[index]
                evData.favourite_count = Number(evData.favourite_count) + 1
                evData.is_already_favourite = "1";
                 return {
                    ...state,
                    evData: [...state.evData.slice(0, index),evData, ...state.evData.slice(index + 1)]
                } 
            }else if(!action.error && action.data.type ==='destination'){
                const index = state.destinationData.data.findIndex(obj=>obj.content_id == action.data.content_id)
                let destData =  state.destinationData.data[index]
                destData.favourite_count = Number(destData.favourite_count) +1
                destData.is_already_favourite = "1";
                console.log(destData, '8899')
                 return {
                    ...state,
                    destData: [...state.destData.slice(0, index),destData, ...state.destData.slice(index + 1)]
                } 
            }

            
        } */

        case RESET_REVIEW_LISTING: {
            return {
                ...state,
                resetReviewList: action.value
            }
        }

        case RESET_SEARCH: {
            return {
                ...state,
                resetSearch: action.value
            }
        }

        case FETCH_USER_BUY_LIST: {
            return {
                ...state,
                userBuyData: action.data
            }
        }
        case GET_MY_STATISTIC: {
            return {
                ...state,
                myStaticData: action.data
            }
        }

    }
    return state;
}